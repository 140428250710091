body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body > div[class^='css-']:last-child {
  z-index: 52;
}

[data-testid='table-pagination-page-size-menu'] {
  z-index: 51 !important;
  pointer-events: all;
}

[data-testid='pagination-page-size-menu-page-size-menu'] {
  z-index: 51 !important;
  pointer-events: all;
}
